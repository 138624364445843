
























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SideBar extends Vue {
  items = []
  initialized = false

  mounted() {
    this.items = [
      { icon: 'fa-cogs', url: 'Settings/Customer/Consent/List', title: 'Email', consentType: 1 },
      { icon: 'fa-cogs', url: 'Settings/Customer/Consent/List', title: 'SMS', consentType: 2 },
    ]
    this.initialized = true
  }

  getUrl(part, consentType) {
    if (part[0] === '/') {
      return part
    } else {
      return {
        name: part,
        params: {
          consentType,
        },
      }
    }
  }
}
